<template>
  <div>
    <div class="hero">
      <video
        src="../assets/dustRide/DustDriveTrailer_Intro.mp4"
        type="video/mp4"
        autoplay
        muted
        loop
        plays-inline
        class="background-video"
      ></video>
      <div class="heroContent">
        <h1>
          Dust Drive <br />
          challenge your skills
        </h1>
        <div>
          <a href="https://play.google.com/store/apps/details?id=com.BroThoughts.DustDrive&hl=en&gl=US" target="_blank"><v-btn class="ma-2" outlined color="orange">
            get on <v-icon> mdi-google-play</v-icon>
          </v-btn></a>
        </div>
      </div>
    </div>
    <div class="">
      <v-card class="" outlined>
        <v-img src="../assets/dustRide/desert.jpg">
          <!-- <div>
          <h1 class="pt-4 ps-4">Latest News</h1>
        </div> -->

          <v-carousel class="mb-0"
            align="center"
            justify="center"
            cycle
            interval="3000"
            height='100%'
            hide-delimiter-background
            show-arrows-on-hover
            delimiter-icon="mdi-car mdi-1px"
            next-icon="mdi-car-sports"
            prev-icon="mdi-car-sports mdi-flip-h"
          >
            <v-carousel-item>
              <v-sheet width="100%" height="100%" tile>
                <v-row class="fill-height" align="center" justify="center">
                  <v-img
                    src="../assets/dustRide/new_horizons.jpg"
                    alt="image"
                  />
                </v-row>
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item>
              <v-sheet width="100%" height="100%" tile>
                <v-row class="fill-height" align="center" justify="center">
                  <v-img src="../assets/dustRide/pick_car.jpg" alt="image" />
                </v-row>
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item>
              <v-sheet
                align="center"
                justify="center"
                width="100%"
                height="100%"
                tile
              >
                <v-row class="fill-height" align="center" justify="center">
                  <v-img
                    src="../assets/dustRide/collect_rewards.jpg"
                    alt="image"
                  />
                </v-row>
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item>
              <v-sheet
                align="center"
                justify="center"
                width="100%"
                height="100%"
                tile
              >
                <v-row class="fill-height" align="center" justify="center">
                  <v-img
                    src="../assets/dustRide/unlimitedRide.jpg"
                    alt="image"
                  />
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>

          <!-- <v-container>
            <div class="container">
              <input type="radio" name="slider" id="item-1" checked />
              <input type="radio" name="slider" id="item-2" />
              <input type="radio" name="slider" id="item-3" />
              <div class="cards">
                <label class="card" for="item-1" id="song-1">
                  <v-img
                    src="../assets/dustRide/new_horizons.jpg"
                    alt="image"
                  />
                </label>
                <label class="card" for="item-2" id="song-2">
                  <v-img src="../assets/dustRide/pick_car.jpg" alt="image" />
                </label>
                <label class="card" for="item-3" id="song-3">
                  <v-img
                    src="../assets/dustRide/collect_rewards.jpg"
                    alt="image"
                  />
                </label>
              </div>
            </div>
          </v-container> -->

          <!-- <v-container class="mt-10 pt-10">
            <v-row>
              <v-col cols="4" class="tall">
                <v-card color="#1F7087" dark elevation="24">
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-img src="../assets/dustRide/new_horizons.jpg">
                     
                    </v-img>
                  </div>
                </v-card>
              </v-col>
      
              <v-col cols="4">
                <v-card color="#1F7087" dark elevation="24">
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-img src="../assets/dustRide/pick_car.jpg">
                    
                    </v-img>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card color="#952175" dark class="rounded-xl" elevation="24">
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-img src="../assets/dustRide/collect_rewards.jpg">
                   
                    </v-img>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card color="#952175" dark class="rounded-xl" elevation="24">
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-img src="../assets/dustRide/collect_rewards.jpg">
                     
                    </v-img>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card color="#952175" dark class="rounded-xl" elevation="24">
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-img src="../assets/dustRide/collect_rewards.jpg">
                    
                    </v-img>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container> -->
        </v-img>
      </v-card>
    </div>

    <v-card max-height="800px">
      <v-img src="../assets/dustRide/shot3.jpg">
        <!-- <h1 style="color: #fff;" class="pa-5">mustang</h1> -->
      </v-img>
      <!-- <div>
          <h1 class="">
            Desert Race <br />
            challenge your racing skills
          </h1>
        </div> -->
    </v-card>

    <div class="">
      <v-img src="../assets/dustRide/ride1.jpg"> </v-img>
    </div>

    <div class="">
      <v-img src="../assets/dustRide/fuelstation.jpg"> </v-img>
    </div>

    <div class="">
      <v-img class="" src="../assets/dustRide/flyride.jpg">
        <div>
          <!-- <span class="mb-n6 white--text text-h2 font-weight-bold" style=" position: absolute; align-content: left; text-align: left; right: 10%; top: 55%;text-shadow: 1px 5px 10px #000000;">
            EVENT IS ON <br />
            <span class="text-h6 ">Join the biggest racing skill Event</span>
            
          </span> -->
        </div>
      </v-img>
    </div>

    <v-card max-height="800px">
      <v-img src="../assets/dustRide/blast_ride.jpg">
        <!-- <h1 style="color: #fff;" class="pa-5">mustang</h1> -->
      </v-img>
      <!-- <div>
          <h1 class="">
            Desert Race <br />
            challenge your racing skills
          </h1>
        </div> -->
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Home",
  data: () => ({}),
};
</script>
<style>
.background-video {
  position: absolute;
  right: 0;
  bottom: 0;
}
@media (min-aspect-ratio: 16/9) {
  .background-video {
    width: 100%;
    height: auto;
  }
}
@media (max-aspect-ratio: 16/9) {
  .background-video {
    width: auto;
    height: 100%;
  }
}

.hero {
  width: 100%;
  height: 100vh;
  position: relative;
  padding: 0 5%;
  display: flex;
  align-items: center;
  justify-content: left;
}

.heroContent {
  font-size: 1.825rem;
  color: #f7be05;
  text-shadow: 1px 5px 10px #000000;
  align-content: left;
  text-align: left;
  right: 25%;
  top: 35%;
  z-index: 1;
}

/* .playerwrapper {

  max-width: 100vw;
  width: 100vw;
  background-image: url("../assets/dustRide/mustang.jpg");

}


.playerbg {
 
  background-image: url("../assets/dustRide/bluecar.jpg");
  clip-path: polygon(60% 0, 100% 0, 100% 100%, 50% 100%);
} */

input[type="radio"] {
  display: none;
}

.card {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  margin: auto;
  transition: transform 0.4s ease;
  cursor: pointer;
}

.container {
  width: 100%;
  max-width: 800px;
  max-height: 600px;
  height: 100%;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.cards {
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: none;
}

#item-1:checked ~ .cards #song-3,
#item-2:checked ~ .cards #song-1,
#item-3:checked ~ .cards #song-2 {
  transform: translatex(-40%) scale(0.8);
  opacity: 0.4;
  z-index: 0;
}

#item-1:checked ~ .cards #song-2,
#item-2:checked ~ .cards #song-3,
#item-3:checked ~ .cards #song-1 {
  transform: translatex(40%) scale(0.8);
  opacity: 0.4;
  z-index: 0;
}

#item-1:checked ~ .cards #song-1,
#item-2:checked ~ .cards #song-2,
#item-3:checked ~ .cards #song-3 {
  transform: translatex(0) scale(1);
  opacity: 1;
  z-index: 1;
}
</style>

<template>
  <v-app>
    <v-app-bar class="pa-0" app dense :color="oncsrollAppbar" flat elevation=1
   elevate-on-scroll v-on:scroll.native="handleScroll"
    >
      <v-toolbar-title
        ><a class="white--text text-decoration-none" href="/"
          >
          <v-img v-if="oncsrollAppbar!='transparent'" src="../src/assets/logo/BroThoughts.png" max-width="25%"></v-img>
          </a></v-toolbar-title
      >
      <v-spacer></v-spacer>
      <!-- <v-btn to="work" class="white--text" plain>
        <h4 class="">WORK</h4>
      </v-btn>
      <v-btn to="about" class="white--text" plain>
        <h4 class="">About Us</h4>
      </v-btn>
      <v-btn to="contact" class="white--text" plain>
        <h4 class="">Contact</h4>
      </v-btn> -->
    </v-app-bar>
    <v-main class="ma-0 pa-0">
      <router-view />
    </v-main>

    <v-footer class="footer" dark padless style="display: block">
      <svg viewBox="0 0 120 28">
        <defs>
          <mask id="xxx">
            <circle cx="7" cy="12" r="40" fill="#fff" />
          </mask>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation="2" result="blur" />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="
           1 0 0 0 0  
           0 1 0 0 0  
           0 0 1 0 0  
           0 0 0 13 -9"
              result="goo"
            />
            <feBlend in="SourceGraphic" in2="goo" />
          </filter>
          <path
            id="wave"
            d="M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z"
          />
        </defs>
        <use id="wave2" class="wave" xlink:href="#wave" x="0" y="0"></use>
      </svg>
      <div>
        <div class="pt-0  footerAddress d-flex">

            <div class="py-5 px-2">
            <span class="px-2">
              <v-icon size="20px" color="#1877F2"
                >mdi-email-outline</v-icon
              ></span
            >
            <span>brothoughtssa@gmail.com</span>
     
          <!-- <v-card-text class="pt-0">
            <span class="px-2"
              ><v-icon size="20px" color="#1877F2"
                >mdi-phone-outline</v-icon
              ></span>
            <span>+91 9941181904</span>
          </v-card-text> -->
            </div>
        </div>


      </div>
      <div v-if="$vuetify.breakpoint.mobile!=true" class="footerContentname">
      <v-btn plain class="topballname white--text text-decoration-none" href="/"><h2>BroThoughts</h2></v-btn>
        <!-- <span class="topball1 flex text-h3 font-weight-bold ">VreLab</span> -->
        </div>
      <div v-if="$vuetify.breakpoint.mobile!=true" class="footerContentSocial">
        <v-btn class="topball1 mx-4" icon>
          <v-icon size="24px" color="#1877F2">mdi-facebook</v-icon>
        </v-btn>
        <v-btn class="topball2 mx-4" icon>
          <v-icon size="24px" color="#1d9bf0">mdi-twitter</v-icon>
        </v-btn>
        <v-btn class="topball3 mx-4" icon>
          <v-icon size="24px" color="#0a66c2">mdi-linkedin</v-icon>
        </v-btn>
        <v-btn class="topball4 mx-4" icon>
          <v-icon size="24px" color="#E4405F">mdi-instagram</v-icon>
        </v-btn>
      </div>

      <v-card class="pt-0">
          <v-divider></v-divider>
          <v-card-text class="py-5 white--text text-center">
            <strong>&#169; BroThoughts</strong> - {{ new Date().getFullYear() }}
            <p class="mb-0"><a class="caption text-decoration-none " href="/privacy-policy">privacy policy</a></p>
          </v-card-text>
        </v-card>

    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    changenavbar:false,
    oncsrollAppbar:"transparent"
  }),
   created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods:{
    handleScroll (event) {
     if(event.target.scrollingElement.scrollTop>200){
      this.oncsrollAppbar="#111010"
     }else{
      this.oncsrollAppbar="transparent"
     }
    }
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>
<style scoped>
.footerContentSocial {
  top: 85px;
  right: 30px;
  position: absolute;
}
.footerContentname {
  top: 90px;
  left: 20px;
  position: absolute;
}
.footerAddress{
  bottom: 60px;
  left: 5px;
  position: absolute;
}

.footer {
  width: 100vw;
  position: relative;
  bottom: 0px;
}
.footer div {
  /* background-color: #000000; */
  margin: -5px 0px 0px 0px;
  padding: 0px;
  color: #fff;
  text-align: center;
}
svg {
  width: 100%;
  height: auto;
}
/* .arrow {
  width: 20px;
  stroke-width: 0.3px;
  stroke: blue;
} */
.topballname{
  animation: ball 2.5s ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-delay: 2.5s;
  cursor: pointer;
}
.topball1 {
  animation: ball 2.5s ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-delay: 6.9s;
  cursor: pointer;
}
.topball2 {
  animation: ball 2.5s ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-delay: 7.2s;
  cursor: pointer;
}
.topball3 {
  animation: ball 2.5s ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-delay: 7.5s;
  cursor: pointer;
}
.topball4 {
  animation: ball 2.5s ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-delay: 7.8s;
  cursor: pointer;
}
.wave {
  animation-delay: 0s;
  animation: wave 1.5s linear;
  animation-iteration-count: infinite;
  fill: #111010;
}

#wave2 {
  animation-duration: 5s;
  animation-direction: reverse;
  opacity: 0.6;
}


@keyframes wave {
  to {
    transform: translateX(-100%);
  }
}
@keyframes ball {
  from {
    transform: translateY(225%);
  }
  to {
    transform: translateY(50%);
  }
}


@keyframes nameball {
  to {
    transform: translateY(60%);
  }
}
</style>





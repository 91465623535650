import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false
Vue.use(vuetify, {
  theme: {
    primary: '#FFA726',
    secondary: '#29B6F6',
    white: '#fff'
}})

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
